import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

const ProjectTemplate = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;
  const { title, } = frontmatter;
  
  return (
    <Layout>
      <SEO title={title} />
        <Img
          className="w-full h-64 pt-0"
          fluid={frontmatter.cover.path.childImageSharp.fluid} 
          alt={frontmatter.cover.desc}
        />
        <div className="pt-16 max-w-5xl mx-auto bg-gray-200">
          <h1 className="font-display text-center uppercase tracking-widest text-3xl text-gray-900 max-w-full text-center pb-16 m-0">{frontmatter.title}</h1>
          <div className="project-md px-16" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    </Layout>
  );
};

export default ProjectTemplate;

export const query = graphql`
query ($slug: String!) {
  site {
    siteMetadata {
      title
    }
  }
  markdownRemark(fields: {slug: {eq: $slug}}) {
    id
    excerpt(pruneLength: 160)
    html
    frontmatter {
      title
      keywords
      extract
      author
      reviewed
      sequence
      cover {
        path {
          childImageSharp {
            fluid {
              base64
              tracedSVG
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
              presentationWidth
              presentationHeight
            }
          }
        }
        desc
      }
    }
  }
}
`;
